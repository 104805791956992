<template>
  <div class="abonos-orden mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Abonos de Compras
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  :items="proveedores"
                  outlined
                  rounded
                  dense
                  label="Proveedores"
                  item-text="proveedor"
                  v-model="proveedor"
                  return-object
                  @change="loadOrdenes()"
                ></v-select>
              </v-col>
              <!-- Ordenes pendientes -->
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Órdenes de compra pendientes
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :search="search"
                      :items="pendientes"
                      :footer-props="{ itemsPerPageText: 'Órdenes' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            class="mt-6"
                            v-model="search"
                          ></v-text-field>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              color="primary"
                              @click="$router.push('/detalleAbonos/' + item.id)"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver orden</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Ordenes pagadas -->
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Órdenes de compra pagadas
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :search="search_pagadas"
                      :items="pagadas"
                      :footer-props="{ itemsPerPageText: 'Órdenes' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            class="mt-6"
                            v-model="search_pagadas"
                          ></v-text-field>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              v-on="on"
                              v-bind="attrs"
                              color="primary"
                              @click="$router.push('/detalleAbonos/' + item.id)"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver orden</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "abonosOrden",
  data: () => ({
    proveedores: [],
    search: "",
    search_pagadas: "",
    proveedor: {},
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Orden",
        value: "folio",
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Abonos",
        value: "abonos",
      },
      {
        text: "Saldo",
        value: "saldo",
      },
      {
        text: "",
        value: "acciones",
      },
    ],
    ordenes: [],
  }),
  methods: {
    loadProveedores() {
      const body = {
        route: "/proveedores",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.proveedores = response.data.data;
        }
      });
    },
    loadOrdenes() {
      const body = {
        route: "/ordenes_credito",
        params: {
          id: this.proveedor.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.ordenes = response.data.data;
        }
      });
    },
  },
  created() {
    this.loadProveedores();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lita_ordenes() {
      return this.ordenes.map((orden) => {
        return {
          fecha: moment(orden.fecha).format("ll"),
          folio: this.$options.filters.folio(orden.folio),
          total: parseFloat(orden.total),
          abonos: parseFloat(orden.abonos),
          saldo: parseFloat(orden.total) - parseFloat(orden.abonos),
          id: orden.id,
        };
      });
    },
    pendientes() {
      return this.lita_ordenes.filter((orden) => {
        return orden.saldo > 0;
      });
    },
    pagadas() {
      return this.lita_ordenes.filter((orden) => {
        return orden.saldo == 0;
      });
    },
  },
};
</script>
